<template>
  <VendorForm mode="Ubah" module="Vendor"> </VendorForm>
</template>

<script>
import VendorForm from './form';

const VendorUpdate = {
  name: 'VendorUpdate',
  components: { VendorForm },
};

export default VendorUpdate;
</script>
